@import "../../variables.scss";

.tile-container {
    box-shadow: 0 3px 5px 0 #b5b0a3;
    position: relative;
}

.timer-clock {
    background: url('/tasks/102/zegar.png') no-repeat;
    width: 214px;
    height: 126px;
    position: absolute;
    top: 0px;
    right: 0px;
    p {
        font-size: 45px;
        margin-top: 45px;
        margin-left: 14px;
        color: #ffffff;
    }    
}

.tile-image {
    display: block;
    width: 100%;

    transition: .3s linear;

    &:hover {
        background: #9d9d9d;
        transform: scale(0.9);
    }

    &.removed {
        transition: 1s linear;
        opacity: 0;
    }

    &.mismatched {
        animation: rotation 1s;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(20deg);
    }
    75% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(0);
    }
}
