@import "./variables";

@font-face {
    font-family: 'LeroyMerlinSans';
       src: url('/fonts/LeroyMerlinSans-Regular.woff') format('woff'),
            url('/fonts/LeroyMerlinSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeroyMerlinSans';
       src: url('/fonts/LeroyMerlinSans-Bold.woff') format('woff'),
            url('/fonts/LeroyMerlinSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;        
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    min-height: 100%;
    font-size: 24px;
    font-family: $arial;
    font-weight: 300;
    color: $text-color;
    background-attachment: fixed;
}

main,
aside,
dl,
dd {
    display: block;
}

#root {
    margin: 0;
    position: relative;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input[type="text"] {
    font-family: $lato;
    font-weight: bold;
    font-size: 22px;
    color: $color3;
}

h1 {
    font-size: 40px;
    font-family: $leroy;
    font-weight: bold;
    text-align: center;
    color: $color9;
    margin: 0 auto;
    text-transform: uppercase;
}

h2 {
    font-family: $leroy;
    font-weight: bold;
    font-size: 60px;
    color: $color2;
    text-align: center;
    text-transform: uppercase;
}

h3 {
    font-size: 40px;
    font-family: $leroy;
    font-weight: bold;
    color: $color9;
    text-transform: uppercase;
}

p {
    font-size: 24px;
    font-family: $kalam;
    font-weight: 300;
    max-width: 100%;
}

form {
    margin: 0;
}

body.with-cookies {
    padding-bottom: 300px;
}

span {
    &.blue {
        color: $color3;
        font-weight: 400;
    }
    &.green {
        color: $color9;
        font-weight: bold;
    }
}

ul {
    list-style-image: url('/images/generator3/arrow.png');
    li {
        font-size: 18px;
        margin-bottom: 15px;
    }
}

.button {
    $buttonHeight: 2em;

    background: $color5;
    color: $color1;
    text-decoration: none;
    font-family: $lato;
    font-weight: bold;
    font-size: 30px;
    padding: 0;
    display: inline-block;
    position: relative;
    line-height: $buttonHeight;
    padding: 0 $buttonHeight/2;
    border: none;
    cursor: pointer;
    border-radius: 1em;

    &:focus,
    &:hover {
        background-color: $color2;
        color: $color1;
    }
}

.button-secondary {
    @extend .button;
    $border-size: 2px;
    background: none;
    border: solid $border-size $color5;
    color: $color4;
    position: relative;

    &:focus,
    &:hover {
        background: $color1;
        border-color: $color2;
        color: $color4;
    }
}

.button-big-blue {
    @extend .button;
    font-size: 46px;
}

.button-image {
    display: inline-block;        
    width: 210px;
    height: 65px;

    &.button-dalej {
        width: 136px;
        height: 60px;
        background: url('/images/buttons/icon-dalej.png');
        &:hover {
            background: url('/images/buttons/icon-dalej-active.png');
        }
    }
    &.button-sprawdz {
        width: 154px;
        height: 46px;
        background: url('/images/buttons/sprawdz.png');
        &:hover {
            background: url('/images/buttons/sprawdz-active.png');
        }
    }
    &.button-pobierz {
        width: 152px;
        height: 66px;
        background: url('/images/buttons/i-pobierz.png');
        &:hover {
            background: url('/images/buttons/i-pobierz-active.png');
        }
    }
    &.button-dosklepu {
        background: url('/images/buttons/idz-do.png');
        &:hover {
            background: url('/images/buttons/idz-do-active.png');
        }
    }
    &.button-dokreatora {
        background: url('/images/buttons/kreator.png');
        &:hover {
            background: url('/images/buttons/kreator-active.png');
        }
    }
}

a {
    color: $color4;
    text-decoration: none;

    &:hover {
        color: $color7;
    }
}

.subtitle {
    font-weight: 400;
    font-size: 28px;
    margin-top: 0px;    
}

.center {
    text-align: center;
}

/* ========= */

legend {
    padding: 0;
    display: table;
}

fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}
