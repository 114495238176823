$color1: #ffffff;
// $color1: #fff8e6;
$color2: #255C63;
// $color2: #f29100;
$color3: #1B83B5;
$color4: #000000;
// $color4: #5D4420;
$color5: rgb(0,111,164);
$color6: #B9DCD2;
$color7: #e41b13;
$color8: #45B286;
$color9: #6CAF22;
$color10: #0A2638;
$color11: #D5E899;


$text-color: $color4;

$lato: 'Lato', sans-serif;
$arial: 'Arial', sans-serif;
$kalam: 'Kalam', cursive;
$leroy: 'LeroyMerlinSans'
